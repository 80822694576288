import React from 'react'
import './Layout.scss'
import './docStyles.scss'
import './sidebarPage.scss'
import { Logotype } from '../../assets/svgs'

const Layout = ({ children, constricted = false }) => {
  return (
    <div className="main">
      <div className={constricted ? 'header constricted' : 'header'}>
        <div className="logo">
          <a href="/">
            <Logotype color="rgb(8, 36, 52)" />
          </a>
        </div>
        <nav>
          {/* <a href="/">Overview</a> */}
          <a href="/documentation">Overview</a>
          <a href="/getting-started">Getting started</a>
          <a href="/api-reference">API reference</a>
          {/* <a href="/docs">API v1</a> */}
          <a href="https://widget.consupedia.se/">Widgets</a>
        </nav>
      </div>
      {children}
      <section
        className="alignfull"
        style={{ backgroundColor: 'rgba(12,13,13,1)' }}
      >
        <div className="section-container">
          <div className="d-none d-lg-block">
            <div className="container">
              <div className="row">
                <div className="col-5">
                  <h3 style={{ width: '10rem' }}>
                    <span style={{ display: 'none' }}>Consupedia</span>
                    <Logotype color="#fff" />
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Layout
