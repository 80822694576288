import * as React from "react";
import { useEffect, useState, useLayoutEffect } from "react";
import Layout from "../../components/Layout/Layout";

// markup
const Docs = () => {
  useLayoutEffect(() => {
    var s = document.createElement("script");
    s.setAttribute(
      "src",
      "https://cdn.jsdelivr.net/npm/redoc@latest/bundles/redoc.standalone.js"
    );
    s.onload = () => console.log("redoc script added");
    document.body.appendChild(s);
  }, []);

  return (
    <Layout>
      <title>Consupedia Product Environment Api v1 Documentation</title>
      <section className="docs">
        <redoc spec-url={"/collection_environment.yml"}></redoc>
      </section>
    </Layout>
  );
};

export default Docs;
